import { Dialog } from 'antd-mobile';
import Bowser from 'bowser';
import { AES, enc } from 'crypto-js';
import { format, set, sub } from 'date-fns';
import { ko } from 'date-fns/locale';
import { Observable, OperatorFunction, buffer, debounceTime, fromEvent, map } from 'rxjs';
import { v4 } from 'uuid';

import { LocalStorageKey } from './constant';

export const isLocal = ['127.0.0.1', 'localhost'].includes(location.hostname);

export const sessionId = () => {
  const id = getLocalStorageValue<string>(LocalStorageKey.sessionId);
  if (id) {
    return id;
  }
  const newId = !isLocal ? v4() : 'hubert';
  setLocalStorageValue(LocalStorageKey.sessionId, newId);
  return newId;
};

// TODO: formatNumber와 병합
export const numberFormat = (price: number, digits = 6) => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: digits,
  }).format(price);
};

export const sleep = async (ms?: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const formatNumber = (value: number, digits = 0) =>
  Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: digits,
  }).format(value);

export const formatDateForKR = (date: string) => {
  return format(new Date(date), 'dd(EEEEEE)', { locale: ko });
};

/** 주문 날짜 */
export const formatOrderDate = (date?: Date) => format(date ?? Date.now(), "yyyy-MM-dd'T'HH:mm:ss+0900");

/**
 * 모든 주문의 마감시간은 배송일 전날 23시로
 * 주문 마감일은 배송일 +1로 표기한다.
 */
export const getOrderDeadline = (deliveryDate: string) => {
  const date = new Date(deliveryDate);
  const deadlintDate = set(sub(date, { days: 1 }), { hours: 23 });
  const now = new Date();

  if (deadlintDate < now) {
    return '마감되었습니다.';
  }

  const deadline =
    now.getDay() === deadlintDate.getDay()
      ? // 마감일의 날짜와 오늘의 날짜가 같으면 '오늘'로 표기한다.
        format(deadlintDate, '오늘 HH시', { locale: ko })
      : now.getDay() + 1 === deadlintDate.getDay()
      ? // 하루 차이는 '내일'로 표기한다.
        format(deadlintDate, '내일 HH시', { locale: ko })
      : format(deadlintDate, 'dd(EEEEEE) HH시', { locale: ko });
  return `${deadline}에 마감됩니다.`;
};

/**
 * - 를 삽입한다.
 */
export function normalizeTel(telNo?: string) {
  if (telNo == null || telNo === '') {
    return '';
  }
  // 숫자 이외에는 모두 제외한다.
  telNo = telNo.replace(/[^0-9]/g, '');

  // 2018-11-15 부터는 050으로 변환해서 FS에 저장하기 때문에 불펼요할 수 있다.
  telNo = telNo.replace(/^090/, '050');

  // 010- , 070-
  let matches = telNo.match(/^(0[17][01])(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  // 050은 4자리 식별번호를 사용하지만 3자리가 익숙하니 12자리가 아닌 경우에는 050에서 끊어준다.
  // 050-AAA?-BBBB
  matches = telNo.match(/^(050)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  // 050X-AAAA-BBBB
  matches = telNo.match(/^(050.)(.{4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  matches = telNo.match(/^(02)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  matches = telNo.match(/^(0..)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  return telNo;
}

export const filterInt = (value: string) => {
  if (/^[-+]?(\d+|Infinity)$/.test(value)) {
    return Number(value);
  } else {
    return NaN;
  }
};

export const getDeliveredAt = (days: number, orderDeliveredAt: string | null, orderDate?: Date) => {
  const now = orderDate ?? new Date();
  return orderDeliveredAt ?? format(new Date(now.setDate(now.getDate() + days)), "yyyy-MM-dd'T'08:00:00+0900");
};

/**
 * 주문완료 상태의 주문에 예상 배송일을 반환한다.
 * 예상 배송일은 서비스 글로벌 설정인 commerceConf.deliveryDateRules의 값에 따라 결정된다.
 * @param dateRules commerceConf.deliveryDateRules
 */
export const getOrderDeliveryDate = (dateRules: number[]) => {
  const now = new Date();
  const weekday = now.getDay();
  const days = dateRules[weekday] ?? 0;
  return format(new Date(now.setDate(now.getDate() + days)), "yyyy-MM-dd'T'08:00:00+0900");
};

const matchOperatorsRe = /[|\\{}()[\]^$+*?.]/g;
export const escapeStringRegExp = (str: string) => {
  return new RegExp(str.replace(matchOperatorsRe, '\\$&'));
};

/**
 * 키워드와 일치하는 아이템(상품명, 태그)을 반환한다.
 * @param keyword
 * @returns
 */
export const getMatchedItems = (keyword: string | null, items?: string[]) => {
  if (!keyword) {
    return null;
  }
  const regex = escapeStringRegExp(keyword);
  return items?.filter((name) => name.match(regex)) || null;
};

/**
 * 값이 undefined인 경우에 대한 처리를 추가한 JSON.parse
 */
export function jsonParseWithCatch<T>(value: string | null): T | undefined {
  try {
    if (typeof value === 'string' && value.startsWith('{')) {
      return value === 'undefined' ? undefined : JSON.parse(value ?? 'null');
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
}

export const parseIfObject = (value: any) => {
  if (typeof value === 'object') {
    return value instanceof Error ? JSON.stringify(value, Object.getOwnPropertyNames(value)) : JSON.stringify(value);
  }
  return value;
};

export const errorObjectToString = (error: any) => (error instanceof Error ? error.message : parseIfObject(error));

export function setLocalStorageValue<T>(key: LocalStorageKey, newValue: T) {
  localStorage.setItem(key, JSON.stringify(newValue));
  window.dispatchEvent(new Event('storage'));
}

export function removeLocalStorageItem(key: LocalStorageKey) {
  localStorage.removeItem(key);
  window.dispatchEvent(new Event('storage'));
}

export function getLocalStorageValue<T>(key: LocalStorageKey) {
  const storedLocalValue = jsonParseWithCatch<T>(localStorage.getItem(key));
  return storedLocalValue;
}

export function observeLocalStorageValue<T>(key: LocalStorageKey) {
  return fromEvent<StorageEvent>(window, 'storage').pipe(map(() => getLocalStorageValue<T>(key)));
}

/**
 * 최근 검색어를 저장한다.
 * - 우선은 localStorage에만 저장한다.
 */
export const saveLatestSearchKeyword = (keyword: string, maxSearchKeywordCount = 20) => {
  const latestSearchKeywords = getLocalStorageValue<string[]>(LocalStorageKey.latestSearchKeywords);
  if (latestSearchKeywords) {
    const index = latestSearchKeywords.findIndex((value) => value === keyword);
    // 중복된 검색어가 있으면 제거한다.
    if (index !== -1) {
      latestSearchKeywords.splice(index, 1);
    }
    // 최대 검색어 개수를 초과하면 가장 오래된 검색어를 제거한다.
    if (latestSearchKeywords.length >= maxSearchKeywordCount) {
      latestSearchKeywords.pop();
    }
    // 새로운 검색어를 배열의 맨 앞에 추가한다.
    latestSearchKeywords.unshift(keyword);
    setLocalStorageValue(LocalStorageKey.latestSearchKeywords, latestSearchKeywords);
  } else {
    setLocalStorageValue(LocalStorageKey.latestSearchKeywords, [keyword]);
  }
};

export async function getPublicIp() {
  try {
    const response = await fetch('https://api64.ipify.org?format=json', {
      cache: 'no-cache',
    });
    if (response.ok) {
      const body = await response.json();
      return body.ip as string;
    } else {
      const response = await fetch('https://us-central1-gooduncles-10f09.cloudfunctions.net/myip', {
        cache: 'no-cache',
      });
      if (response.ok) {
        const body = await response.json();
        return body.ip as string;
      }
      return 'unknown';
    }
  } catch (error) {
    console.error('[getPublicIp]', error);
    return 'error';
  }
}

export const getLastObject = (obj: any) => {
  const keys = Object.keys(obj);
  return obj[keys[keys.length - 1]];
};

export const MobileAlert = (
  title: string,
  content?: React.ReactNode,
  confirm?: {
    text: string;
    onClick: () => void;
  }
) => {
  Dialog.alert({
    title,
    content,
    confirmText: confirm ? confirm.text : '확인',
    bodyStyle: {
      wordBreak: 'keep-all',
      whiteSpace: 'pre-line',
      textAlign: 'center',
    },
    onConfirm: confirm ? confirm.onClick : undefined,
  });
};

export const textSort = (a: string, b: string) => {
  return a < b ? -1 : a > b ? 1 : 0;
};

/**
 * 문자열이 n보다 긴 경우 말줄임표를 붙인다.
 * @returns
 */
export const truncate = (str: string, n: number) => {
  return str.length > n ? str.slice(0, n - 1) + '...' : str;
};

type BufferDebounce = <T>(debounce: number) => OperatorFunction<T, T[]>;
export const bufferDebounce: BufferDebounce = (debounce) => (source) =>
  new Observable((observer) =>
    source.pipe(buffer(source.pipe(debounceTime(debounce)))).subscribe({
      next(x) {
        observer.next(x);
      },
      error(err) {
        observer.error(err);
      },
      complete() {
        observer.complete();
      },
    })
  );

export function insert<T>(array: T[], index: number, newItem: T) {
  return [...array.slice(0, index), newItem, ...array.slice(index)];
}

export const encryptAES = (text: string) => {
  const key = process.env.REACT_APP_PROJECT_ID;
  if (!key) {
    throw new Error('No key');
  }
  return AES.encrypt(text, key).toString();
};

export const decryptAES = (text: string) => {
  const key = process.env.REACT_APP_PROJECT_ID;
  if (!key) {
    throw new Error('No key');
  }
  return AES.decrypt(text, key).toString(enc.Utf8);
};

export const encodeBase64 = (text: string) => {
  return enc.Base64.stringify(enc.Utf8.parse(text));
};

export const decodeBase64 = (text: string) => {
  return enc.Base64.parse(text).toString(enc.Utf8);
};

export const downloadFromUrl = (url: string, filename: string) => {
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', url);
  downloadAnchorNode.setAttribute('download', filename);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

/**
 * 이메일 주소를 정규화한다.
 */
export const normalizeEmail = (email: string): string => {
  // 전체 이메일을 소문자로 변환
  const normalizedEmail = email.toLowerCase();
  // 간단한 정규화를 위한 로컬 부분 처리
  // 연속된 점을 하나의 점으로 변환 (엄격한 RFC 준수를 위해서는 추가 검증 필요)
  return normalizedEmail.replace(/\.\.+/g, '.');
};

/**
 * 이메일 주소를 검증한다.
 */
export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

/**
 * 배열로된 금지 문자 목록을 받아서 문자열에서 해당 문자를 제거한다.
 */
export const removeCharsFromString = (str: string, charsToRemove: string[]) =>
  charsToRemove.reduce((currentStr, charToRemove) => {
    return currentStr.replace(new RegExp(charToRemove, 'g'), '');
  }, str);

/**
 * 원단위를 10원 단위로 반올림한다.
 */
export const roundToNearestTen = (num: number) => {
  return Math.round(num / 10) * 10;
};

/**
 * 브라우저 정보를 가져온다.
 */
export const getBrowserInfo = () => {
  try {
    const browser = Bowser.parse(navigator.userAgent);
    return (
      browser ?? {
        browser: 'unknown',
        os: 'unknown',
        platform: 'unknown',
        engine: 'unknown',
      }
    );
  } catch (error) {
    console.error('[getBrowserInfo]', error);
  }
  return {
    browser: 'unknown',
    os: 'unknown',
    platform: 'unknown',
    engine: 'unknown',
  };
};
