import { EditOutlined } from '@ant-design/icons';
import { Button, Input, message } from 'antd';
import { FC, useCallback, useState } from 'react';

import { errorObjectToString } from 'src/lib/1/util';
import { CommerceLogger } from 'src/lib/4/logger';

import LabeledItem from 'src/components/atoms/LabeledItem/LabeledItem';

import classes from './LabeledItemWithInput.module.scss';

const logger = CommerceLogger.getInstance();

type LabeledItemWithInput = {
  label: string;
  text: string;
  description?: string;
  onUpdateData: (data: any) => Promise<void>;
  hideEditButton?: boolean;
  hideSuccessMessage?: boolean;
};

const LabeledItemWithInput: FC<LabeledItemWithInput> = ({
  label,
  text,
  description,
  onUpdateData,
  hideEditButton,
  hideSuccessMessage,
}) => {
  const [loading, setLoading] = useState(false);
  const [onEdit, setOnEdit] = useState(false);
  const [value, setValue] = useState<string | null>(null);

  const cancelOnEdit = (e: any) => {
    e.preventDefault();
    setOnEdit(false);
    setValue(null);
  };

  const onChangeValue: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);
  };

  const handleOnUpdateData = useCallback(async () => {
    setLoading(true);
    try {
      await onUpdateData(value);
      setOnEdit(false);
      setValue(null);
      if (!hideSuccessMessage) {
        message.success(`${label} 정보가 변경되었습니다.`);
      }
    } catch (error) {
      const msg = errorObjectToString(error);
      logger.logCommerce(`${label} 변경 실패: ${msg}`, {
        level: 'error',
      });
      message.error(`${label} 변경에 실패했습니다.\n문제가 반복되는 경우 카카오톡 고객센터로 연락주세요.`);
    }
    setLoading(false);
  }, [hideSuccessMessage, label, onUpdateData, value]);

  return (
    <LabeledItem
      label={
        <div className={classes.labelWithButton}>
          <label>{label}</label>
          {!hideEditButton && (
            <>
              {!onEdit ? (
                <Button size='small' icon={<EditOutlined />} onClick={() => setOnEdit(true)}>
                  {label} 변경
                </Button>
              ) : (
                <div className={classes.flexRow}>
                  <Button
                    size='small'
                    type='primary'
                    loading={loading}
                    disabled={!(value && value?.length > 0)}
                    onClick={handleOnUpdateData}>
                    변경하기
                  </Button>
                  <Button size='small' loading={loading} onClick={cancelOnEdit}>
                    취소
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      }
      text={<>{!onEdit ? text : <Input size='large' defaultValue={text} minLength={1} onChange={onChangeValue} />}</>}
      description={description}
    />
  );
};

export default LabeledItemWithInput;
