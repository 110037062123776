import { StoreBase, StoreCategory, StoreDoc, storeStateList } from '@gooduncles/gu-app-schema';
import { Button, Select, Switch, message } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GaEventType } from 'src/schema/schema-ga-event';

import { kakaoTalkUrl } from 'src/lib/1/constant';
import { DaumPostcodeResponse } from 'src/lib/1/schema-daum-postcode-api';
import { MobileAlert, errorObjectToString, isValidEmail, sleep } from 'src/lib/1/util';
import { FirebaseManager } from 'src/lib/2/firebase-manager';
import { callSendVerificationEmail, sendGaEvent, updateStore, updateUser } from 'src/lib/3/firebase-short-cut';
import { DeviceDoc } from 'src/lib/3/schema-app';
import { CommerceLogger } from 'src/lib/4/logger';
import { WebViewMessageHandler } from 'src/lib/5/webveiw-message-handler';

import { UserExceptFavoriteProducts } from 'src/redux/models/authModel';
import { selectStoreCategories } from 'src/redux/slices/home';
import { useAppSelector } from 'src/redux/store';

import ChatIcon from 'src/assets/icons/chat.svg';

import TopNavigationBar from '../Common/TopNavigationBar/TopNavigationBar';
import DaumPostcodeModal from '../Modal/DaumPostcodeModal/DaumPostcodeModal';
import LabeledItem from '../atoms/LabeledItem/LabeledItem';
import LabeledItemWithInput from '../molecules/LabeledItemWithInput/LabeledItemWithInput';
import classes from './UserProfile.module.scss';

const logger = CommerceLogger.getInstance();

const openKakaoChat = () => window.open(kakaoTalkUrl, '_blank');

interface UserProfileProps {
  user: UserExceptFavoriteProducts;
  store: StoreDoc;
}

const webViewMessageHandler = WebViewMessageHandler.getInstance();
const firebaseManager = FirebaseManager.getInstance();
const devicePath = 'device';

const updateStoreAddressWithGA = async (daumResponse: DaumPostcodeResponse, storeId: string) => {
  const {
    address = '',
    roadAddress = '',
    jibunAddress = '',
    sido = '',
    sigungu = '',
    bname = '',
    hname = '',
    roadname = '',
  } = daumResponse ?? {};
  const storeBase: Partial<StoreBase> = {
    address,
    roadAddress,
    jibunAddress,
    sido,
    sigungu,
    bname,
    hname,
    roadname,
  };
  await updateStore(storeId, storeBase);
  sendGaEvent({
    name: GaEventType.SETTING,
    eventParams: {
      key: 'storeAddress',
      value: storeBase,
    },
  });
};

const UserProfile: FC<UserProfileProps> = ({ user, store }) => {
  const storeCategories = useAppSelector(selectStoreCategories);
  const [isLoading, setIsLoading] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  // 주소 편집 모달
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [daumResponse, setDaumResponse] = useState<DaumPostcodeResponse | null>(null);

  // 기기의 푸시 알림 수신 여부를 나타낸다.
  const allowNotification = !!user.allowNotification;
  const deviceId = user.deviceTokenId;
  const [appPermission, setAppPermission] = useState(true);
  const [permissionLoading, setPermissionLoading] = useState(false);
  const notificationPermission = appPermission && !user.allowNotification;

  /**
   * 주소 변경 과정을 안내하는 모달을 띄운다.
   */
  const showEditAddressRequestModal = useCallback(() => {
    MobileAlert(
      '',
      <p>
        배송 주소지 변경은
        <span className='openKakao' onClick={openKakaoChat}>
          <img src={ChatIcon} alt='이웃삼촌 카카오톡 링크' />
          카카오톡 채널(이웃삼촌)
        </span>
        으로 문의 바랍니다.
      </p>
    );
  }, []);

  /** 비밀번호 재설정 이메일 발송 */
  const onSendResetPasswordEmail = useCallback(async () => {
    setIsLoading(true);
    try {
      await firebaseManager.sendPasswordResetEmail(user.email);
      setResetEmailSent(true);
    } catch (error) {
      const msg = errorObjectToString(error);
      logger.logCommerce(`비밀번호 재설정 이메일 발송 실패: ${msg}`, {
        level: 'error',
      });
      message.error(
        '비밀번호 재설정 이메일 발송에 실패했습니다.\n문제가 반복되는 경우 카카오톡 고객센터로 연락주세요.'
      );
    }
    setIsLoading(false);
  }, [user.email]);

  /** 매장명 업데이트 */
  const onUpdateStoreName = useCallback(
    async (storeName: string) => {
      await updateStore(store._id, { storeName });
      logger.logCommerce(`매장 이름을 변경한 매장이 있습니다.\n${store.storeName} → ${storeName}`, {
        channel: '4-앱-알림',
      });
      sendGaEvent({
        name: GaEventType.SETTING,
        eventParams: {
          key: 'storeName',
          value: storeName,
        },
      });
    },
    [store._id, store.storeName]
  );

  /** 대표 업종 업데이트 */
  const onUpdateStoreCategory = useCallback(
    async (cateogry: StoreCategory) => {
      setIsLoading(true);
      try {
        await updateStore(store._id, { categories: [cateogry] });
        message.success('대표 업종이 변경되었습니다.');
        logger.logCommerce(
          `대표 업종을 변경한 매장이 있습니다.\n매장명: ${store.storeName}\n${store.categories?.join(
            ','
          )} → ${cateogry}`,
          {
            channel: '4-앱-알림',
          }
        );
        sendGaEvent({
          name: GaEventType.SETTING,
          eventParams: {
            key: 'storeCategory',
            value: cateogry,
          },
        });
      } catch (error) {
        const msg = errorObjectToString(error);
        logger.logCommerce(`대표 업종 변경 실패: ${msg}`, {
          level: 'error',
        });
        message.error('대표 업종 변경에 실패했습니다.\n문제가 반복되는 경우 카카오톡 고객센터로 연락주세요.');
      }
      setIsLoading(false);
    },
    [store._id, store.categories, store.storeName]
  );

  /** 주소 검색 결과 선택 */
  const onSearchComplete = useCallback((data: DaumPostcodeResponse) => {
    setDaumResponse(data);
  }, []);

  /** 주소 검색 결과 업데이트 */
  const onUpdateStoreAddress = useCallback(async () => {
    if (daumResponse) {
      setIsLoading(true);
      try {
        await updateStoreAddressWithGA(daumResponse, store._id);
        setDaumResponse(null);
        logger.logCommerce(
          `매장주소를 변경한 매장이 있습니다.\n매장명: ${store.storeName}\n${store.jibunAddress} → ${daumResponse.jibunAddress}`,
          {
            channel: '4-앱-알림',
          }
        );
        message.success('주소가 변경되었습니다.');
      } catch (error) {
        const msg = errorObjectToString(error);
        logger.logCommerce(`주소 변경 실패: ${msg}`, {
          level: 'error',
        });
        message.error('주소 변경에 실패했습니다.\n문제가 반복되는 경우 카카오톡 고객센터로 연락주세요.');
      }
      setIsLoading(false);
    } else {
      message.error('선택된 주소가 없습니다.');
    }
  }, [daumResponse, store._id, store.jibunAddress, store.storeName]);

  /** 주소 편집취소 */
  const cancelEditAddress = useCallback(async () => {
    await sleep(200);
    setDaumResponse(null);
    setShowSearchModal(false);
  }, []);

  /** 상세 주소 업데이트 */
  const onUpdateStoreAddressDetail = useCallback(
    async (addressDetail: string) => {
      await updateStore(store._id, { addressDetail });
      logger.logCommerce(
        `매장 상세주소를 변경한 매장이 있습니다.\n매장명: ${store.storeName}\n${store.addressDetail} → ${addressDetail}`,
        {
          channel: '4-앱-알림',
        }
      );
      sendGaEvent({
        name: GaEventType.SETTING,
        eventParams: {
          key: 'addressDetail',
          value: addressDetail,
        },
      });
    },
    [store._id, store.addressDetail, store.storeName]
  );

  /** 일일 가격 알림 */
  const onChangeAllowKakaoNotification = async (checked: boolean) => {
    setIsLoading(true);
    try {
      await updateUser(user._id, { kakaoNotification: checked });
      sendGaEvent({
        name: GaEventType.SETTING,
        eventParams: {
          key: 'kakaoNotification',
          value: checked,
        },
      });
    } catch (error) {
      const msg = errorObjectToString(error);
      logger.logCommerce(`일일 가격 알림 변경 실패: ${msg}`, {
        level: 'error',
      });
      message.error('일일 가격 알림 변경에 실패했습니다.\n문제가 반복되는 경우 카카오톡 고객센터로 연락주세요.');
    }
    setIsLoading(false);
  };

  /** 주문 마감시간 알림 */
  const onChangeAllowDeadlineNotification = async (checked: boolean) => {
    setIsLoading(true);
    try {
      await updateUser(user._id, { deadlineNotification: checked });
      sendGaEvent({
        name: GaEventType.SETTING,
        eventParams: {
          key: 'deadlineNotification',
          value: checked,
        },
      });
    } catch (error) {
      const msg = errorObjectToString(error);
      logger.logCommerce(`주문 마감시간 알림 변경 실패: ${msg}`, {
        level: 'error',
      });
      message.error('주문 마감시간 알림 변경에 실패했습니다.\n문제가 반복되는 경우 카카오톡 고객센터로 연락주세요.');
    }
    setIsLoading(false);
  };

  /** 새벽 알림 금지 */
  const onChangeDisableNotificationAtDawn = async (checked: boolean) => {
    setIsLoading(true);
    try {
      await updateUser(user._id, { disableNotificationAtDawn: checked });
      sendGaEvent({
        name: GaEventType.SETTING,
        eventParams: {
          key: 'disableNotificationAtDawn',
          value: checked,
        },
      });
    } catch (error) {
      const msg = errorObjectToString(error);
      logger.logCommerce(`새벽 알림 금지 설정 변경 실패: ${msg}`, {
        level: 'error',
      });
      message.error('새벽 알림 금지 설정 변경에 실패했습니다.\n문제가 반복되는 경우 카카오톡 고객센터로 연락주세요.');
    }
    setIsLoading(false);
  };

  /** 매장의 대표 이메일 변경 */
  const onUpdateStoreEmail = useCallback(
    async (email: string) => {
      if (!email) {
        message.error('올바른 값을 입력해주세요.');
        return;
      }
      const isValid = isValidEmail(email);
      if (!isValid) {
        message.error('올바른 이메일 형식이 아닙니다.');
        return;
      }
      setIsLoading(true);
      try {
        const result = await callSendVerificationEmail({
          verificationAction: 'STORE_EMAIL',
          email,
          extraInfo: {
            storeId: store._id,
            email,
          },
        });
        if (result.data.result !== 'success') {
          throw new Error(result.data.message);
        }
        MobileAlert(
          '인증 메일 발송 완료',
          <span>
            매장 대표 이메일 변경 요청이 완료되었습니다.
            <br />
            이메일을 확인하여 변경을 완료해주세요.
          </span>
        );
      } catch (error) {
        const msg = errorObjectToString(error);
        logger.logCommerce(`매장 대표 이메일 변경 요청 실패: ${msg}`, {
          level: 'error',
        });
        message.error('매장 대표 이메일 변경에 실패했습니다.\n문제가 반복되는 경우 카카오톡 고객센터로 연락주세요.');
      }
      setIsLoading(false);
    },
    [store._id]
  );

  // const onChangeAllowNotification = async (checked: boolean) => {
  //   webViewMessageHandler.requestPermission();
  //   // TODO: 웹뷰로 권한 요청 메시지전달
  //   // 결과가 있으면 같이 처리한다.
  //   setPermissionLoading(true);
  //   await updateUser(user._id, { allowNotification: checked });
  //   setPermissionLoading(false);
  // };

  useEffect(() => {
    if (deviceId) {
      // 현재 상태를 수신만 한다.
      const subscription = firebaseManager.observeDoc<DeviceDoc>(`${devicePath}/${deviceId}`).subscribe((device) => {
        if (device) {
          const permission = !['DENIED', 'NOT_DETERMINED'].includes(device.permission);
          setAppPermission(permission);
        } else {
          // 기기 설정이 별도로 존재하지 않으면 웹 사용자로 간주하여 true로 둔다.
          setAppPermission(true);
        }
      });

      return () => subscription.unsubscribe();
    }
  }, [allowNotification, deviceId]);

  return (
    <>
      <TopNavigationBar title='회원정보' leftButton />
      <div className={classes.userInfo}>
        <LabeledItem
          label={
            <div className={classes.addressLabel}>
              <span>아이디(이메일)</span>
              {resetEmailSent ? (
                '발송완료'
              ) : (
                <Button size='small' loading={isLoading} onClick={onSendResetPasswordEmail}>
                  비밀번호 재설정 이메일 발송
                </Button>
              )}
            </div>
          }
          text={user.email}
        />
        <LabeledItem label='휴대폰번호' text={user.userTel} />
      </div>
      <div className={classes.storeInfo}>
        <LabeledItemWithInput label='매장명' text={store.storeName} onUpdateData={onUpdateStoreName} />
        <div>
          <LabeledItemWithInput
            label='매장 대표 이메일'
            text={store.storeEmail || ''}
            onUpdateData={onUpdateStoreEmail}
            hideSuccessMessage={true}
          />
          <p className={classes.storeEmailInfo}>※ 거래 내역, 기타 정보를 수신할 이메일입니다.</p>
        </div>
        <LabeledItem
          label='대표 업종'
          text={
            <Select
              placeholder='선택된 업종이 없습니다.'
              allowClear
              style={{ width: '100%' }}
              size='large'
              onChange={onUpdateStoreCategory}
              value={store.categories?.[0]}
              options={storeCategories
                .filter((cg) => cg.key !== 'all')
                .sort((a, b) => (a.index !== b.index ? a.index - b.index : a.title.localeCompare(b.title)))
                .map((cg) => ({
                  label: `${cg.emoji} ${cg.title}`,
                  value: cg.key,
                }))}
            />
          }
        />
        <LabeledItem label='매장상태' text={storeStateList[store.state]} className={store.state} />
        <LabeledItem
          label={
            <div className={classes.addressLabel}>
              <span>매장주소</span>
              {!daumResponse ? (
                <Button size='small' onClick={showEditAddressRequestModal} loading={isLoading}>
                  주소 변경 요청
                </Button>
              ) : (
                <div className={classes.flexRow}>
                  <Button size='small' type='primary' loading={isLoading} onClick={onUpdateStoreAddress}>
                    변경하기
                  </Button>
                  <Button size='small' loading={isLoading} onClick={cancelEditAddress}>
                    취소
                  </Button>
                </div>
              )}
            </div>
          }
          text={daumResponse ? daumResponse.jibunAddress : store.jibunAddress}
          description={daumResponse ? daumResponse.roadAddress : store.roadAddress}
        />
        <LabeledItemWithInput
          label='상세주소'
          text={store.addressDetail ?? ''}
          onUpdateData={onUpdateStoreAddressDetail}
          hideEditButton
        />
      </div>
      <div className={classes.notificationInfo}>
        <div className={classes.notificationTitle}>
          <h2>알림 설정</h2>
          <p>※ 이웃삼촌 카톡을 차단하면 알림을 수신할 수 없습니다.</p>
        </div>
        <LabeledItem
          label='일일 가격 알림'
          text={
            <span className={classes.flexRow}>
              <Switch
                checked={!!user.kakaoNotification}
                loading={isLoading}
                onChange={onChangeAllowKakaoNotification}
              />
              {user.kakaoNotification && (
                <span className={classes.inform}>*최근 구매했거나, 즐겨찾기한 상품의 가격 알림</span>
              )}
            </span>
          }
        />
        <LabeledItem
          label='주문 마감시간 알림'
          text={
            <span className={classes.flexRow}>
              <Switch
                checked={!!user.deadlineNotification}
                loading={isLoading}
                onChange={onChangeAllowDeadlineNotification}
              />
              {user.deadlineNotification && <span className={classes.inform}>*주문 마감 15분전 알림</span>}
            </span>
          }
        />
        <LabeledItem
          label='새벽시간 알림(품절,수량 변경) 금지'
          text={
            <span className={classes.flexRow}>
              <Switch
                checked={!!user.disableNotificationAtDawn}
                loading={isLoading}
                onChange={onChangeDisableNotificationAtDawn}
              />
              {user.disableNotificationAtDawn && <span className={classes.inform}>*아침 7시에 알림</span>}
            </span>
          }
        />
        {/* <div>
          <p>push permission: {notificationPermission}</p>
          <Switch checked={notificationPermission} onChange={onChangeAllowNotification} loading={permissionLoading} />
        </div> */}
      </div>
      <div className={classes.deleteAccount}>
        <Link to='/delete-account' className={classes.linkItem}>
          회원탈퇴
        </Link>
      </div>
      <DaumPostcodeModal visible={showSearchModal} setVisible={setShowSearchModal} onComplete={onSearchComplete} />
    </>
  );
};

export default UserProfile;
